<button mat-button [matMenuTriggerFor]="menuHelp">
  <mat-icon>help</mat-icon>
  Help
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #menuHelp="matMenu">

  <a mat-menu-item
      id="getting-started"
      href="https://docs.google.com/document/d/1tLd0j0ksBoHvomfuz9YdGvKbWwr-q_QR9kkBDAzHnW8/edit?usp=sharing"
      >
    Getting Started
  </a>
  <a mat-menu-item
    id="contact-us"
    href="mailto:support@aimhub.org"
    >
    Contact Support
  </a>

</mat-menu>
