import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';

import { ReportsModule } from './reports/reports.module';
import { SearchModule } from './search/search.module';
import { PublisherModule } from './publisher/publisher.module';
import { BookModule } from './book/book.module';
import { FileFormatModule } from './file-format/file-format.module';
import { HoldingBookSourceModule } from './holding-book-source/holding-book-source.module';
import { HoldingBookModule } from './holding-book/holding-book.module';
import { BatchIsbnModule } from './batch-isbn/batch-isbn.module';

import { CampusModule } from './campus/campus.module';
import { CampusSystemModule } from './campus-system/campus-system.module';
import { AcademicTermModule } from './academic-term/academic-term.module';
import { UserModule } from './user/user.module';

import { ExchangeRequestStatusModule } from './exchange-request-status/exchange-request-status.module';
import { ExchangeRequestModule } from './exchange-request/exchange-request.module';
import { ExchangeRequestLogModule } from './exchange-request-log/exchange-request-log.module';
import { ExchangeRequestFileModule } from './exchange-request-file/exchange-request-file.module';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AppInjector } from './app-injector.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TestComponent } from './test/test.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    SharedModule,
    AuthModule,
    CoreModule,
    BrowserAnimationsModule,
    ReportsModule,
    SearchModule,
    PublisherModule,
    BookModule,
    FileFormatModule,
    HoldingBookSourceModule,
    HoldingBookModule,
    BatchIsbnModule,
    CampusModule,
    CampusSystemModule,
    AcademicTermModule,
    UserModule,
    ExchangeRequestStatusModule,
    ExchangeRequestModule,
    ExchangeRequestLogModule,
    ExchangeRequestFileModule,
    ReactiveFormsModule,
    AppRoutingModule,
  ],
  providers: [
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    // Store module's injector in the AppInjector class
    AppInjector.setInjector(injector);
  }
}
