import {Component, Input} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';
import { Publisher } from '../publisher';


/**
 * A set of form fields to manipulate a publisher object.
 * @example
 * <form [formGroup]="formComposite" (ngSubmit)="onSubmit()">
 *   <div formGroupName="publisher">
 *     <app-publisher-formgroup [group]="formComposite.controls.publisher">
 *     </app-publisher-formgroup>
 *   </div>
 *   <button type="submit">Submit</button>
 * </form>
 */
@Component({
  selector: 'app-publisher-fields',
  templateUrl: './publisher-fields.component.html',
  styleUrls: ['./publisher-fields.component.css']
})
export class PublisherFieldsComponent extends BaseFieldsComponent {
  @Input() formGroup: FormGroup; // Added to as attempt to fix missing buttons
}


/**
 * Static function that returns a publisher field formgroup.
 *
 * @param {Publisher} publisher - publisher object to initialize fields with.
 * @returns {FormGroup} intialized set of publisher form fields.
 */
export function initPublisherFormGroup(publisher: Publisher): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    'id': [publisher.id, [] ],
    'name': [publisher.name, [ Validators.required ] ],
    'url': [publisher.url, [] ],
    'permissions_url': [publisher.permissions_url, [] ],
    'parent_company': [publisher.parent_company, [] ],
    'contact_name': [publisher.contact_name, [] ],
    'email': [publisher.email, [] ],
    'phone': [publisher.phone, [] ],
    'fax': [publisher.fax, [] ],
    'address1': [publisher.address1, [] ],
    'address2': [publisher.address2, [] ],
    'city': [publisher.city, [] ],
    // @TODO: convert to autocomplete select. #106
    'state': [publisher.state, [ ] ],
    'postal_code': [publisher.postal_code, [
      Validators.pattern('[0-9]{5}\(-[0-9]{4}\)?')
    ] ],
  });

  return form;
}
